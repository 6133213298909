import { Model } from '@vuex-orm/core';
import { AxiosResponse } from 'axios';
import InternalOrderAPI from '@/api/InternalOrderAPI';

export default class InternalOrder extends Model {
    public static entity = 'InternalOrder';

    public static fields() {
        return {
            id: this.string(null),
            attachments: this.attr([]),
            orderNumber: this.string(''),
            orderStatus: this.attr(''),
            system: this.string(''),
            createdAt: this.string(''),
            manufactureDate: this.string(''),
            deliveryDate: this.string(''),
            type: this.string(''),
        };
    }

    public static async getById(orderId: string) {
        let internalOrder;

        try {
            internalOrder = (await InternalOrderAPI.getById(orderId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        // this.insertOrUpdate({
        //     data: InternalOrder.data,
        // });

        return Promise.resolve(internalOrder);
    }

    public static async createNew(internalOrderData: {
        order: { system: string; quantity: number };
        projectId: string;
        offerId: string;
    }) {
        let internalOrder;

        try {
            internalOrder = (await InternalOrderAPI.createNew(internalOrderData)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        // this.insertOrUpdate({
        //     data: InternalOrder.data,
        // });

        return Promise.resolve(internalOrder);
    }

    public static async getAttachment(attachmentId: string) {
        let internalOrder;

        try {
            internalOrder = (await InternalOrderAPI.getAttachment(attachmentId)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }
        // this.insertOrUpdate({
        //     data: InternalOrder.data,
        // });

        return Promise.resolve(internalOrder);
    }

    public id!: string;
    public attachments!: any[];
    public orderNumber!: string;
    public orderStatus!: { code: string };
    public system!: string;
    public createdAt!: string;
    public manufactureDate!: string;
    public deliveryDate!: string;
    public type!: string;
}
