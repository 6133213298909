
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TechnologistFormOrder } from '@/interfaces/components/projectNew/TechnologistFormOrder';
import ProductSystem from '@/models/ProductSystem';

@Component({
    name: 'TechnologistOrderFormOrders',
    components: {},
})
export default class TechnologistOrderFormOrders extends Vue {
    @Prop({ required: true }) private orders!: TechnologistFormOrder[];
    @Prop({ required: true }) private systems!: ProductSystem[];
    @Prop({ required: true }) private orderErrors!: { [key: string]: { system: boolean; quantity: boolean } };

    private addOrder() {
        this.$emit('addOrder');
    }

    private deleteOrder(index: number) {
        this.$emit('deleteOrder', index);
    }

    private setInitialOrders() {
        this.$emit('setInitialOrders');
    }

    private async mounted() {
        this.setInitialOrders();
    }
}
