import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse, AxiosError } from 'axios';

export default class InternalOrderAPI {
    public static async getById(id: string) {
        let internalOrder;

        try {
            internalOrder = (await ApiController.get(ApiRoutes.internalOrder.getById(id))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(internalOrder);
    }

    public static async createNew(internalOrderData: {
        order: { system: string; quantity: number };
        projectId: string;
        offerId: string;
    }) {
        let internalOrder;

        const request = {
            data: {
                type: 'project_internal_order',
                attributes: {
                    ...internalOrderData.order,
                    orderNumber: internalOrderData.offerId,
                    type: 'order',
                },
                relationships: {
                    project: {
                        data: {
                            type: 'projects',
                            id: internalOrderData.projectId,
                        },
                    },
                },
            },
        };

        try {
            internalOrder = (await ApiController.post(ApiRoutes.internalOrder.create, request)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(internalOrder);
    }

    public static async getAttachment(id: string) {
        let internalOrders;

        try {
            internalOrders = (await ApiController.get(ApiRoutes.internalOrder.getAttachment(id))) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(internalOrders);
    }
}
