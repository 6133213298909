
import { Vue, Component, Prop } from 'vue-property-decorator';
import { AxiosResponse } from 'axios';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import TechnologistOrderFormGeneralInfo from '@/components/views/NewProjectView/Order/TechnologistOrderFormGeneralInfo.vue';
import TechnologistOrderFormOrders from '@/components/views/NewProjectView/Order/TechnologistOrderFormOrders.vue';
import { TechnologistFormOrder } from '@/interfaces/components/projectNew/TechnologistFormOrder';
import { technologistOrderFormConfiguration, prepareTechnologistOrderFormData } from '@/helpers/OrderHelper';
import { TechnologistFormField } from '@/interfaces/components/projectNew/TechnologistFormField';
import Offer from '@/models/Offer';
import TechnologistOrder from '@/models/TechnologistOrder';
import { uploadAttachmentWithRelationshipToLead } from '@/helpers/attachments/AttachmentsHelper';
import InternalOrder from '@/models/InternalOrder';
import { UserRepository } from '@/repositories/UserRepository';
import ProductSystem from '@/models/ProductSystem';
import { ProductSystemRepository } from '@/repositories/ProductSystemRepository';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import { TechnologistForm } from '@/interfaces/components/projectNew/TechnologistForm';

@Component({
    name: 'TechnologistOrderForm',
    components: {
        TechnologistOrderFormGeneralInfo,
        TechnologistOrderFormOrders,
    },
})
export default class TechnologistOrderForm extends Vue {
    @Prop({ required: true }) private project!: any;
    @Prop({ required: true }) private selectedOffer!: any;

    private visible: boolean = false;
    // Create formData based on form fields
    private formData: Partial<Record<keyof TechnologistForm, any>> = {};
    private orders: TechnologistFormOrder[] = [];
    private errors: { [key: string]: string } = {};
    private orderErrors: { [key: string]: { system: boolean; quantity: boolean } } = {};
    private loadingOverlay = new LoadingOverlayHelper(this, {});

    private get orderFormConfiguration() {
        return technologistOrderFormConfiguration;
    }

    private get clientsPaymentTypeId() {
        const paymentTypeId = this.project?.client?.paymentType?.id;

        if (!paymentTypeId) {
            return null;
        }

        return paymentTypeId;
    }

    private get systems() {
        return ProductSystemRepository.getAll();
    }

    private async openPopup() {
        this.visible = true;
    }

    private closePopup() {
        this.visible = false;
    }

    private setInitialFormValues() {
        this.formData = technologistOrderFormConfiguration.formFields.reduce((acc: Record<string, any>, field) => {
            if (field.type === 'number') {
                acc[field.name as keyof TechnologistForm] = 0;
            } else if (field.type === 'textarea' || field.type === 'text') {
                acc[field.name as keyof TechnologistForm] = '';
            } else if (field.type === 'dropdown') {
                acc[field.name as keyof TechnologistForm] = null;
            } else if (field.type === 'attachment') {
                acc[field.name as keyof TechnologistForm] = [];
            } else if (field.type === 'date') {
                acc[field.name as keyof TechnologistForm] = null;
            }
            return acc;
        }, {} as Partial<TechnologistForm>);
    }

    private validateField(field: TechnologistFormField) {
        if (!field.required) {
            delete this.errors[field.name];
            return;
        }

        const value = this.formData[field.name as keyof TechnologistForm];
        let isValid = true;

        if (field.type === 'number' && value === 0) {
            isValid = false;
        } else if ((field.type === 'text' || field.type === 'textarea') && typeof value === 'string' && !value.trim()) {
            isValid = false;
        } else if ((field.type === 'dropdown' || field.type === 'date') && (value === null || value === undefined)) {
            isValid = false;
        } else if (field.type === 'attachment' && Array.isArray(value) && value.length === 0) {
            isValid = false;
        }

        if (!isValid) {
            this.$set(this.errors, field.name, `${this.$t(field.label)} ${this.$t('is required')}`);
        } else {
            this.$delete(this.errors, field.name);
        }
    }

    private validateForm(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.orderFormConfiguration.formFields.forEach((field) => {
                if (field.required) this.validateField(field);
            });

            this.orders.forEach((order, i) => {
                if (!order.system || order.quantity === 0) {
                    console.log(i, order.system, order.quantity);
                    this.$set(this.orderErrors, i.toString(), {
                        system: !order.system,
                        quantity: order.quantity === 0,
                    });
                } else {
                    this.$delete(this.orderErrors, i.toString());
                }
            });

            if (Object.keys(this.orderErrors).length > 0) {
                this.$notification.error({
                    message: this.$t('Error') as string,
                    description: this.$t('Please fill all orders.') as string,
                });
                reject({ ...this.orderErrors });
            }

            if (Object.keys(this.errors).length === 0) {
                resolve();
            } else {
                this.$notification.error({
                    message: this.$t('Error') as string,
                    description: Object.values(this.errors).join(', '),
                });
                reject({ ...this.errors });
            }
        });
    }

    private get currentUser() {
        return UserRepository.getCurrentUser();
    }

    private async submitForm() {
        let offerId;

        try {
            if (this.orders.length === 0) {
                this.$notification.error({
                    message: this.$t('Error') as string,
                    description: this.$t('Please add at least one order.') as string,
                });
                return;
            }

            await this.validateForm();
        } catch (err) {
            console.error('Validation failed:', err);
            return;
        }

        try {
            this.loadingOverlay.start();

            // First step - create offer if there is no selected offer
            if (!this.selectedOffer) {
                const projectId = this.project.id;
                offerId = (await Offer.createNew(String(projectId), this.clientsPaymentTypeId))?.data?.id as string;
                await Offer.getById(offerId);
            }

            await this.$nextTick();

            if (!offerId) {
                offerId = this.selectedOffer.id;
            }

            console.log(this.selectedOffer, 'after creation');
            const formattedFormData = prepareTechnologistOrderFormData(this.formData);

            // Next step, send form data on technologist orders relation on offer
            const technologistOrderFormRequest = await TechnologistOrder.createNew(
                formattedFormData,
                offerId,
                this.currentUser?.id ?? '1'
            );
            console.log(technologistOrderFormRequest);

            // Next step, upload attachments
            await this.uploadAttachments();
            console.log('after attachments uploading');

            // Next step, create internal orders based on form orders
            await this.createOrders(offerId);
            console.log('after internal orders creation');

            // Last step, create custom technologist order
            await Offer.orderByTechnologist(offerId);
            // console.log('after ordering by technologist');
        } catch (err) {
            console.error('Error creating order', err);
        } finally {
            // Update project at the end
            EventBus.$emit(EventBusEvents.updateProject, { shouldUpdateOffer: true });
            this.loadingOverlay.stop();
            this.closePopup();
        }
    }

    private async uploadAttachments() {
        const attachments = this.formData.attachments;

        if (!attachments || attachments.length === 0) {
            return;
        }

        const leadId = this.project?.lead?.id;

        if (!leadId) {
            return Promise.resolve();
        }

        const promises = [];

        for (const attachment of attachments) {
            const file = (attachment as any).originFileObj;

            if (!file) {
                continue;
            }

            promises.push(uploadAttachmentWithRelationshipToLead(leadId, file, () => {}));
        }

        try {
            await Promise.all(promises);
        } catch (error) {
            console.error(error);
        }
    }

    private async createOrders(newOfferId: string) {
        const promises = [];

        for (const order of this.orders) {
            promises.push(
                InternalOrder.createNew({ order, projectId: this.project.id, offerId: newOfferId }).then(
                    (response: AxiosResponse) => {
                        console.log('InternalOrder: ', response);
                    }
                )
            );
        }

        try {
            await Promise.all(promises);
        } catch (error) {
            console.error(error);
        }
    }

    private addOrder() {
        const order = {
            system: '',
            quantity: 0,
        };

        this.orders.push(order);
        this.formData.numOfOrders = this.orders.length.toString();
    }

    private deleteOrder(index: number) {
        this.orders.splice(index, 1);
        this.formData.numOfOrders = this.orders.length.toString();
        this.orderErrors.hasOwnProperty(index.toString()) && this.$delete(this.orderErrors, index.toString());
    }

    private setInitialOrders() {
        if (!this.formData.numOfOrders) {
            return;
        }

        this.orders = new Array(parseInt(this.formData.numOfOrders)).fill({
            system: '',
            quantity: 0,
        });
    }

    private onNumberOfOrdersChange() {
        if (!this.formData.numOfOrders) {
            return;
        }

        if (this.orders.length === parseInt(this.formData.numOfOrders)) {
            return;
        }

        if (this.orders.length < parseInt(this.formData.numOfOrders)) {
            const difference = parseInt(this.formData.numOfOrders) - this.orders.length;

            for (let i = 0; i < difference; i++) {
                this.orders.push({
                    system: '',
                    quantity: 0,
                });
            }
        }
    }

    private async fetchSystems() {
        try {
            this.loadingOverlay.start();
            await ProductSystem.getAll();
        } catch (error) {
            console.error(error);
        } finally {
            this.loadingOverlay.stop();
        }
    }

    private async created() {
        this.setInitialFormValues();

        if (this.systems.length === 0) {
            await this.fetchSystems();
        }

        EventBus.$on(EventBusEvents.showTechnologistOrderForm, this.openPopup);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.showTechnologistOrderForm, this.openPopup);
    }
}
